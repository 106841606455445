import * as preact from 'preact';
import { Component, createRef, h } from 'preact';
import { route } from 'preact-router';
import { withText, Text } from 'preact-i18n';
import * as TextMask from 'vanilla-text-mask';
import classnames from 'classnames';

import {
    Transaction, Card, TransactionVerify, PaymentIntent
} from '../models';
import {
    makeSureTransactionWasVerified, Shared, validateEmail
} from '../utils';
import PayButtonComponent from './pay-button.component';
import { Observable, Subscription, from, concat, Subject, of, BehaviorSubject } from 'rxjs';
import {
    distinctUntilChanged, debounceTime,
    switchMap, tap, catchError
} from 'rxjs/operators';
import { Config } from '../app.configs';
import { TransactionService } from '../services/transaction.service';
import { GeoService } from '../services/geo.service';
import RedirectModal from '../services/redirect-modal.service';
import CardComponent from './shared/card.component';
import { setupPostForm, removeHTMLElement, autoCompleteKey } from '../utils/dom';
import { TranasctionErrorComponent } from './shared/transaction-error.component';
import SelectComponent from './select.component';

interface GeoCountry {
    id?: number;
    name: string;
    code: string;
    translation: string;
}
interface GeoCity {
    id?: number;
    name: string;
    translation: string;
}
interface GeoState {
    id?: number;
    name: string;
    iso_code: string;
    translation: string;
}
interface State {
    transaction?: Transaction
    error?: string;
    loading: boolean;
    brandIcon: string;
    formValid: boolean;
    formStep1Valid: boolean;
    formStep2Valid: boolean;
    cardNumberValid: boolean;
    expDateValid: boolean;
    cardCvvValid: boolean;
    billing_details: any;
    showZip: boolean;
    zip_code_placeholder: string;
    error_code: string;
    card: Card;
    customer: any;
    step: 'continue' | 'submit';
    selectedCountry: GeoCountry;
    selectedState: GeoState;
    selectedCity: GeoCity;
    countryLoading: boolean,
    stateLoading: boolean,
    cityLoading: boolean
}

@withText({
    country_label: 'card.country',
    country_placeholder: 'card.country_placeholder',
    search_placeholder: 'card.search_placeholder',
    city_label: 'card.city',
    city_placeholder: 'card.city_placeholder',
    line1_label: 'card.address',
    line1_placeholder: 'card.address_placeholder',
    state_label: 'card.state',
    state_placeholder: 'card.state_placeholder',
    zip_label: 'card.zip',
    zip_placeholder: 'card.zip_placeholder',
    firstname_label: 'customer.firstname',
    firstname_placeholder: 'customer.firstname_placeholder',
    lastname_label: 'customer.lastname',
    lastname_placeholder: 'customer.lastname_placeholder',
    email_label: 'customer.email',
    email_placeholder: 'customer.email_placeholder',
    next_button_text: 'pay_button.continue',
})
export default class CybersourceCardComponent extends Component<any, State> {
    state: State = {
        loading: false,
        brandIcon: 'fad fa-credit-card',
        formValid: false,
        formStep1Valid: false,
        formStep2Valid: false,
        cardNumberValid: true,
        expDateValid: true,
        cardCvvValid: true,
        card: null,
        showZip: false,
        zip_code_placeholder: null,
        error_code: '',
        billing_details: {
            city: null,
            line1:  null,
            state:  null,
            country:  null,
            zip:  null
        },
        selectedCountry: null,
        selectedState: null,
        selectedCity: null,
        customer: {
            firstname: null,
            lastname: null,
            email: null,
        },
        step: 'continue',
        countryLoading: false,
        stateLoading: false,
        cityLoading: false
    };
    transactionService = new TransactionService;
    geoService = new GeoService;
    country$: Observable<any[]>;
    countrySource = new BehaviorSubject<string>('');
    state$: Observable<any[]>;
    stateSource = new BehaviorSubject<string>('');
    city$: Observable<any[]>;
    citySource = new BehaviorSubject<string>('');

    token: string = '';
    intent: PaymentIntent;
    card: Card;
    countryRef = createRef();
    cityRef = createRef();
    stateRef = createRef();
    cardHandler: any;
    cardErrors: any = {};
    cardValidation: any = null;
    form: HTMLFormElement;

    cardBrandToFa: any = {
        visa: 'fab fa-cc-visa',
        mastercard: 'fab fa-cc-mastercard',
        amex: 'fab fa-cc-amex',
        'american-express': 'fab fa-cc-amex',
        discover: 'fab fa-cc-discover',
        diners: 'fab fa-cc-diners-club',
        'diners-club': 'fab fa-cc-diners-club',
        jcb: 'fab fa-cc-jcb'
    }
    zipCodeRef = preact.createRef();

    subscription: Subscription;
    verifyTransaction: TransactionVerify;

    constructor(props) {
        super();

        this.zipCodeInputMask = this.zipCodeInputMask.bind(this);

        this.subscription = makeSureTransactionWasVerified((verifyTrans) => {
            this.token = Shared.token;
            Shared.paymentMode.next('cybersource');
            this.verifyTransaction = verifyTrans;
            this.loadCountries();
            const transaction = verifyTrans.transaction;
            const customer = transaction.customer;

            this.setState({
                customer: {
                    firstname: customer.firstname,
                    lastname: customer.lastname,
                    email: customer.email
                }
            });
        });

        this.createSearchSubscribers();
    }

    createSearchSubscribers() {
        // Country
        this.country$ = concat(
            this.loadCountries(),
            this.countrySource.pipe(
                debounceTime(200),
                distinctUntilChanged((cur: string, prev: string) => {
                    if (cur === null) { // Force reload on null value
                        return false;
                    }

                    return cur === prev;
                }),
                tap(() => this.setState({countryLoading: true})),
                switchMap((term: string) => this.loadCountries(term).pipe(
                    catchError(() => of([])),
                    tap(() => this.setState({countryLoading: false}))
                ))
            )
        );

        // States
        this.state$ = concat(
            this.loadStates(),
            this.stateSource.pipe(
                debounceTime(200),
                distinctUntilChanged((prev: string, cur: string) => {
                    if (cur === null) { // Force reload on null value
                        return false;
                    }

                    return cur === prev;
                }),
                tap(() => this.setState({stateLoading: true})),
                switchMap((term: string) => this.loadStates(term).pipe(
                    catchError(() => of([])),
                    tap(() => this.setState({stateLoading: false}))
                ))
            )
        );

        // Cities
        this.city$ = concat(
            this.loadCities(),
            this.citySource.pipe(
                debounceTime(200),
                distinctUntilChanged((cur: string, prev: string) => {
                    if (cur === null) { // Force reload on null value
                        return false;
                    }

                    return cur === prev;
                }),
                tap(() => this.setState({cityLoading: true})),
                switchMap((term: string) => this.loadCities(term).pipe(
                    catchError(() => of([])),
                    tap(() => this.setState({cityLoading: false}))
                ))
            )
        );
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    componentDidMount() {
        this.initCardFields();
    }

    componentDidUpdate(previousProps: Readonly<any>) {
        if (previousProps.lang !== this.props.lang) {
            // reload selects
            this.clearSelectComponents([this.countryRef, this.cityRef, this.stateRef]);
        }
    }

    zipCodeInputMask() {
        if (this.state.billing_details.country === 'US') {
            if (this.state.billing_details.zip?.length > 5) {
                return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
            } else {
                return [/\d/, /\d/, /\d/, /\d/, /\d/];
            }
        } else if (this.state.billing_details.country === 'CA') {
            return [/[A-Z]/i, /\d/, /[A-Z]/i, ' ', /\d/, /[A-Z]/i, /\d/];
        } else {
            return (new Array(10)).fill(/\w/);;
        }
    }

    translateGeoObject(o) {
        const lang = this.props.lang;
        if (o.translation) {
            try {
                const trans = JSON.parse(o.translation);
                if (trans[lang]) {
                    o.name = trans[lang];
                }
            } catch(e) {}
        }

        return o;
    }

    initCardFields() {
        TextMask.maskInput({
            inputElement: this.zipCodeRef.current,
            guide: false,
            mask: this.zipCodeInputMask
        });
    }

    openDialog(id: string, url: string, data: any) {
        const iframeId = `fexf-${id}`;
        RedirectModal.create(`fexm-${id}`, iframeId, '');

        const form = setupPostForm(iframeId, url, data);

        form.submit();

        RedirectModal.open(`fexm-${id}`, () => {
            this.checkStatus(this.token, this.intent.reference);
        });

        setTimeout(() => {
            removeHTMLElement(form);
        }, 500);
    }

    updateCard(card) {
        this.setState({ card: card }, () => {
            this.checkCardCustomerFormValidity();
        });
    }

    loadCountries(query: string = '') {
        return from(
            this.geoService.countries(query)
            .then(countries => {
                return countries.map(c => this.translateGeoObject(c) )
            })
        );
    }

    loadStates(query: string = '') {
        const country = this.state.selectedCountry;

        return from(
            this.geoService.states(country?.code, query)
            .then(states => {
                return states.map(s => this.translateGeoObject(s) )
            })
        );
    }

    loadCities(query: string = '') {
        const country = this.state.selectedCountry;

        // Load cities if country is valid
        return from(
            this.geoService.cities(country?.code, query)
            .then(cities => {
                return cities.map(c => this.translateGeoObject(c) )
            })
        );
    }

    isUsOrCanada() {
        const country = this.state.selectedCountry;
        return ['US', 'CA'].includes(country?.code);
    }

    updateCountry(selectedCountry: any) {
        let zip_code_placeholder = null;

        if (selectedCountry?.code === 'US') {
            zip_code_placeholder = '94303-2564';
        } else if (selectedCountry?.code === 'CA') {
            zip_code_placeholder = 'K1A 0B2';
        }
        this.setState({ zip_code_placeholder, selectedCountry });

        // Update country in billing, and set state and city to null
        const billings = [
            { value: selectedCountry?.code, key: 'country' },
            { value: null, key: 'state' },
            { value: null, key: 'city' }
        ];

        // Clear state and city selects
        this.clearSelectComponents([this.cityRef, this.stateRef]);

        this.updateBillingInformation(billings);

        setTimeout(() => {
            this.stateSource.next(null);
            this.citySource.next(null);
        }, 300);
    }

    updateState(selectedState: GeoState) {
        this.setState({ selectedState });

        const value = this.isUsOrCanada() ? selectedState?.iso_code : selectedState?.name;
        // Update state in billing, and set city to null
        const billings = [
            { value, key: 'state' }
        ];

        this.updateBillingInformation(billings);
    }

    updateCity(selectedCity: GeoCity) {
        this.setState({ selectedCity });

        // Update state in billing
        this.updateBillingInformation(selectedCity?.name, 'city');
    }

    updateBillingInformation(values, key = null, callback = () => {}) {
        const billing_details = this.state.billing_details;

        if (!Array.isArray(values)) {
            values = [{ value: values, key }];
            // Multiple
        }

        values.forEach(elemt => {
            billing_details[elemt.key] = elemt.value;
        });

        if (typeof key == 'function') {
            callback = key;
        }

        this.setState({ billing_details: { ...billing_details } }, () => {
            this.checkBillingFormValidity();
            callback();
        });
    }

    updateCustomerInformation(e, key) {
        const customer = this.state.customer;
        customer[key] = e.target.value;
        this.setState({ customer: {...customer} }, () => {
            this.checkCardCustomerFormValidity();
        });
    }

    checkCardCustomerFormValidity(): boolean {
        let formValid = this.state.card !== null;

        for (const key in this.state.customer) {
            if (!this.customerIsValid(key)) {
                formValid = false;
            }
        }

        return formValid;
    }

    checkBillingFormValidity(): boolean {
        let formValid = true;

        for (const key in this.state.billing_details) {
            if (!this.state.billing_details[key]) {
                formValid = false;
            }
        }

        return formValid;
    }

    checkFormsValidity() {
        return this.checkCardCustomerFormValidity() && this.checkBillingFormValidity();
    }

    billingIsValid(key) {
        // Check state value for US and CA
        return this.state.billing_details[key];
    }

    customerIsValid(key) {
        const defaultCheck = typeof this.state.customer[key] === 'string' &&
            this.state.customer[key].trim() !== '';

        if (key === 'email') { // Validate email
            return defaultCheck && validateEmail(this.state.customer[key]);
        } else {
            return defaultCheck;
        }
    }

    clearSelectComponents(refs) {
        refs.forEach(ref => ref && ref.current.clear());
    }

    submitForm() {
        this.form?.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    }

    handleSubmit = (e: any) => {
        e.preventDefault();

        if (!this.checkFormsValidity()) {
            return;
        }

        this.setState({ loading: true });

        // Base on card response
        this.transactionService.createCybersourcePayment(this.token, { address: this.state.billing_details, customer: this.state.customer })
            .then(({ intent, cybersource_data }) => {
                Shared.intent = intent; // Update intent
                this.intent = intent;

                // Add Card information
                cybersource_data.card_type = this.state.card.toCybersourceCardType();
                cybersource_data.card_number = this.state.card.number;
                cybersource_data.card_expiry_date = this.state.card.toCybersourceExpDate();
                cybersource_data.card_cvn = this.state.card.cvv;

                this.openDialog(intent.reference, Config.cybersource_url, cybersource_data);
                this.setState({ loading: false });
            })
            .catch(({message}) => {
                this.setState({ error: message, error_code: null, loading: false });
            });
    }

    render(trans) {
        return (
            <form id="card" class="payment-container d-flex flex-column h-100" onSubmit={this.handleSubmit} autoComplete="off" ref={ (ref) => { this.form = ref; } }>
                <div class="modal-body mb-auto">
                    <div class={ classnames({ 'd-none': this.state.step === 'submit' }) }>
                        <CardComponent onChanged={ (card) => this.updateCard(card) } />

                        <div class="row">
                            <div class="col-md-6 pr-3 pr-md-1">
                                <div class="form-group form-group-with-label">
                                   <label for="lastname">
                                        {trans.lastname_label}
                                    </label>
                                    <input id="lastname" type="text"
                                        autoComplete={autoCompleteKey('lastname')}
                                        name={autoCompleteKey('lastname')}
                                        value={this.state.customer.lastname}
                                        required={true}
                                        class={classnames('form-control', {'is-invalid': !this.customerIsValid('lastname')})}
                                        placeholder={trans.lastname_placeholder}
                                        onInput={(e) => this.updateCustomerInformation(e, 'lastname')}/>
                                </div>
                            </div>
                            <div class="col-md-6 pl-3 pl-md-1">
                                <div class="form-group form-group-with-label">
                                    <label for="firstname">
                                        {trans.firstname_label}
                                    </label>
                                    <input id="firstname" type="text"
                                        autoComplete={autoCompleteKey('firstname')}
                                        name={autoCompleteKey('firstname')}
                                        value={this.state.customer.firstname}
                                        required={true}
                                        class={classnames('form-control', {'is-invalid': !this.customerIsValid('firstname')})}
                                        placeholder={trans.firstname_placeholder}
                                        onInput={(e) => this.updateCustomerInformation(e, 'firstname')}/>
                                </div>
                            </div>
                        </div>

                        <div class="form-group form-group-with-label">
                            <label for="email">
                                {trans.email_label}
                            </label>
                            <input type="text"
                                id="email"
                                autoComplete={autoCompleteKey('email')}
                                name={autoCompleteKey('email')}
                                required={true}
                                value={this.state.customer.email}
                                class={classnames('form-control', {'is-invalid': !this.customerIsValid('email')})}
                                placeholder={trans.email_placeholder}
                                onInput={(e) => this.updateCustomerInformation(e, 'email')}/>
                        </div>
                    </div>
                    <div class={ classnames({ 'd-none': this.state.step === 'continue' }) }>
                        {
                            this.state.step === 'submit' &&
                            <p class="mb-1 font-weight-bold">
                                <a role="button" class="back-link" onClick={ () => { this.setState({ step: 'continue' }) } }>
                                    <i class="fad fa-chevron-left"></i> <Text id="card.back_card_customer" />
                                </a>
                            </p>
                        }
                        <div class="row">
                            <div class="col-sm-6 pr-3 pr-md-1">
                                <div class="form-group">
                                    <label for="country">
                                        {trans.country_label}
                                    </label>
                                    <SelectComponent
                                        ref={this.countryRef}
                                        inputId="country"
                                        items={this.country$}
                                        typehead={this.countrySource}
                                        trackBy="id"
                                        valueKey="id"
                                        labelKey="name"
                                        isInvalid={!this.billingIsValid('country')}
                                        onChange={ (selection) => this.updateCountry(selection)}
                                        loading={this.state.countryLoading}
                                        placeholder={trans.country_placeholder}
                                        searchPlaceholder={trans.search_placeholder}></SelectComponent>
                                </div>
                            </div>
                            <div class="col-sm-6 pl-3 pl-md-1">
                                <div class="form-group">
                                    <label for="state">
                                        {trans.state_label}
                                    </label>
                                    <SelectComponent
                                        ref={this.stateRef}
                                        inputId="state"
                                        items={this.state$}
                                        typehead={this.stateSource}
                                        trackBy="id"
                                        valueKey="id"
                                        labelKey="name"
                                        isInvalid={!this.billingIsValid('state')}
                                        onChange={ (selection) => this.updateState(selection)}
                                        loading={this.state.stateLoading}
                                        placeholder={trans.state_placeholder}
                                        searchPlaceholder={trans.search_placeholder}></SelectComponent>
                                </div>
                            </div>
                        </div>
                        <div class="form-group form-group-with-label">
                            <label for="line1">
                                {trans.line1_label}
                            </label>
                            <input id="line1" type="text"
                                autoComplete={autoCompleteKey('line1')}
                                name={autoCompleteKey('line1')}
                                required={true}
                                class={classnames('form-control', {'is-invalid': !this.billingIsValid('line1')})}
                                placeholder={trans.line1_placeholder}
                                onInput={(e: any) => this.updateBillingInformation(e.target.value, 'line1')}/>

                        </div>
                        <div class="row">
                            <div class="col-sm-6 pr-3 pr-md-1">
                                <div class="form-group">
                                    <label for="city">
                                        {trans.city_label}
                                    </label>
                                    <SelectComponent
                                        ref={this.cityRef}
                                        inputId="city"
                                        items={this.city$}
                                        typehead={this.citySource}
                                        trackBy="id"
                                        valueKey="id"
                                        labelKey="name"
                                        isInvalid={!this.billingIsValid('city')}
                                        onChange={ (selection) => this.updateCity(selection)}
                                        loading={this.state.cityLoading}
                                        placeholder={trans.city_placeholder}
                                        searchPlaceholder={trans.search_placeholder}></SelectComponent>
                                </div>
                            </div>
                            <div class="col-sm-6 pl-3 pl-md-1">
                                <div class="form-group form-group-with-label">
                                    <label for="zipcode">{trans.zip_label + ( ' ' + (this.state.zip_code_placeholder ?? '') )}</label>
                                    <input id="zipcode" type="text"
                                        autoComplete={autoCompleteKey('zipcode')}
                                        name={autoCompleteKey('zipcode')}
                                        ref={ this.zipCodeRef }
                                        required={true}
                                        class={classnames('form-control', {'is-invalid': !this.billingIsValid('zip')})}
                                        placeholder={trans.zip_placeholder + ( ' ' + (this.state.zip_code_placeholder ?? '') )}
                                        onInput={(e: any) => this.updateBillingInformation(e.target.value, 'zip')}/>
                                </div>
                            </div>
                        </div>
                        <span class="small zipcode-info">
                            <i class="fad fa-info-circle"></i> { ' ' }

                            { this.state.billing_details.country === 'us' && <Text id="card.us_zip_code" /> }
                            { this.state.billing_details.country === 'ca' && <Text id="card.ca_zip_code" /> }
                            { !['us', 'ca'].includes(this.state.billing_details.country) && <Text id="card.no_zip_code" /> }

                        </span>
                    </div>
                    <TranasctionErrorComponent error={this.state.error} error_code={this.state.error_code} />
                </div>


                <div class="modal-footer">
                    { this.state.step === 'continue' ?
                        <PayButtonComponent loading={ this.state.loading }
                            disabled={ !this.checkCardCustomerFormValidity() }
                            onClick={ () => { this.setState({ step: 'submit' }) } }
                            buttonText={trans.next_button_text}/>
                    :
                        <PayButtonComponent loading={ this.state.loading }
                            disabled={ !this.checkBillingFormValidity() }
                            onClick={ () => this.submitForm()}/>
                    }
                </div>
            </form>
        )
    }

    private checkStatus(token: string, payment_intent_reference: string) {
        this.transactionService.paymentStatus('cybersource', token, payment_intent_reference)
            .then((intent: PaymentIntent) => {
                Shared.intent = intent;
                if (intent.status === 'approved') {
                    route('/status/success', true);
                } else { // Show form again
                    this.setState({
                        error: 'Transaction échouée. Veuillez reessayer',
                        error_code: intent.last_error_code,
                        loading: false
                    });
                }
            });
    }
}
