import { route } from 'preact-router';
import { Config } from '../app.configs';
import { TransactionVerify } from '../models/transaction-verify';

export const MOBILE_MONEY_MODES = [
    'mtn',
    'mtn_open',
    'mtn_ecw',
    'moov',
    'mtn_ci',
    'moov_tg',
    'togocel',
    'orange_ci',
    'orange_sn',
    'free_sn',
    'airtel_ne',
    'orange_ml',
    'mtn_open_gn'
];

export const MOBILE_MONEY_MODES_WITHOUT_PHONE = [
    'orange_ci',
    'orange_sn',
    'orange_ml'
];

export const CREDIT_CARD_MODES = [
    'stripe_gw',
    'stripe_gw_eu',
    'uba',
    'flutterwave',
    'flutterwave_eu',
    'cybersource'
];

export const TPE_MODES = [
    'ecobank_tpe',
    'orabank_tpe'
];

export function isMobile() {
    return window.matchMedia('(max-width: 500px)').matches;
}

export function redirectToPaymentMode(transVerify: TransactionVerify) {
    const cardEnabled = transVerify.getCardMode();

    if (isMobile()) {
        return route('/menu', true);
    }

    if (transVerify.transaction.customer.account_id == Config.evisaAccountId) {
        if(
            transVerify.transaction.mode &&
            cardEnabled &&
            isCardPayementMode(transVerify.transaction.mode)
        ) {
            routeTocard(cardEnabled);
        } else if (
            transVerify.transaction.mode &&
            isMobilePaymentMode(transVerify.transaction.mode)
        ) {
            route('/mobile-money', true);
        }  else if (cardEnabled) {
            routeTocard(cardEnabled);
        } else if (isMobilePaymentModeAllowed(transVerify)) {
            route('/mobile-money', true);
        } else {
            route('/404', true);
        }
    } else {
        if (
            transVerify.transaction.mode &&
            isMobilePaymentMode(transVerify.transaction.mode)
        ) {
            route('/mobile-money', true);
        } else if (
            transVerify.transaction.mode &&
            isCardPayementMode(transVerify.transaction.mode)
        ) {
            routeTocard(cardEnabled);
        } else if (isMobilePaymentModeAllowed(transVerify)) {
            route('/mobile-money', true);
        } else if (cardEnabled) {
            routeTocard(cardEnabled);
        } else {
            route('/404', true);
        }
    }
}

function routeTocard(cardMode) {
    switch (cardMode) {
        case 'stripe_gw':
        case 'uba':
        case 'cybersource':
            route('/card', true);
            break;
        case 'flutterwave':
            route('/card-wave', true);
            break;
        default:
            route('/404', true);
            break;
    }
}

export function isMobilePaymentMode(modes: string | string[]) {
    if (!modes) { return false; }

    if (!Array.isArray(modes)) {
        modes = [modes];
    }

    const diff = modes.filter(m => MOBILE_MONEY_MODES.includes(m));
    return diff.length > 0;
}

export function isMobilePaymentModeAllowed(transVerify: TransactionVerify) {
    const modes = transVerify.getAvailableModes();

    return isMobilePaymentMode(modes);
}

export function isCardPayementMode(modes: string | string[]) {
    if (!modes) { return false; }

    if (!Array.isArray(modes)) {
        modes = [modes];
    }

    const diff = modes.filter(m => {
        return CREDIT_CARD_MODES.includes(m)
    });
    return diff.length > 0;
}
