import { ModelInterface } from './model.interface';

export class Currency implements ModelInterface {
    id: number;
    name: string;
    iso: string;
    code: string;
    prefix: string;
    suffix: string;
    div: number;
    created_at: string;
    updated_at: string;
    modes: string;

    /**
     * Class constructor
     * @param  {any = null} attrs
     */
    constructor(attrs: any = null) {
        if (attrs) {
            this.build(attrs);
        }
    }

    /**
     * Build the object attributes
     * @param {any} attrs
     */
    build(attrs: any): void {
        this.id = attrs.id;
        this.name = attrs.name;
        this.iso = attrs.iso;
        this.code = attrs.code;
        this.prefix = attrs.prefix;
        this.suffix = attrs.suffix;
        this.div = attrs.div;
        this.created_at = attrs.created_at;
        this.updated_at = attrs.updated_at;
        this.modes = attrs.modes;
    }

    /**
     * Return a JSON object representation of the object
     * @return {Object}
     */
    toJson(): any {
        return {
            id: this.id,
            name: this.name,
            iso: this.iso,
            code: this.code,
            prefix: this.prefix,
            suffix: this.suffix,
            div: this.div,
            modes: this.modes
        };
    }

    /**
     * Return a clone of the model
     * @return {Currency}
     */
    clone(): Currency {
        return new Currency(this.toJson());
    }
}
