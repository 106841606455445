import { Component, h } from 'preact';
import { route } from 'preact-router';
import { Text } from 'preact-i18n';
import classnames from 'classnames';
import { Subscription } from 'rxjs';
import { PhoneNumber, Transaction, PaymentIntent } from '../models';
import { TransactionService } from '../services';
import {
    makeSureTransactionWasVerified, Shared,
    MOBILE_MONEY_MODES, MOBILE_MONEY_MODES_WITHOUT_PHONE
} from '../utils';
import PayButtonComponent from './pay-button.component';
import { Config } from '../app.configs';
import RedirectModal from '../services/redirect-modal.service';
import { TranasctionErrorComponent } from './shared/transaction-error.component';

interface Props { }
interface State {
    transaction?: Transaction
    mode?: string;
    prefix?: string;
    phone: PhoneNumber;
    loading: boolean,
    error?: string;
    formValid: boolean;
    availableModes: string[];
    error_code;
}

export default class MobileMoneyComponent extends Component<Props, State> {
    state: State = {
        mode: '',
        prefix: 'bj',
        phone: new PhoneNumber({ country: 'BJ' }),
        loading: false,
        formValid: false,
        availableModes: [],
        error_code: null
    };
    service = new TransactionService;
    token: string;
    subscription: Subscription;
    intent: PaymentIntent;

    componentDidMount() {
        this.subscription = makeSureTransactionWasVerified((verifyTrans) => {
            this.token = Shared.token;
            const transaction = verifyTrans.transaction;
            const modes = verifyTrans.getAvailableModes();

            const availableModes = MOBILE_MONEY_MODES.filter((m) => modes.includes(m));
            const mode = transaction.mode || availableModes[0];

            this.setState({ transaction, availableModes });

            if (transaction.customer.phone_number) {
                const phone = transaction.customer.phone_number;
                this.setState({
                    phone, formValid: this.formValid(mode, phone)
                }, () => {
                    this.modeChange(mode);
                });
            } else {
                this.modeChange(mode);
            }
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    modeChange(mode: string) {
        this.setState({ mode });
        Shared.paymentMode.next(mode);
        const phone = this.state.phone;

        switch (mode) {
            case 'mtn':
            case 'moov':
            case 'mtn_open':
            case 'mtn_ecw':
                phone.country = 'BJ';
                break;
            case 'mtn_ci':
            case 'orange_ci':
                phone.country = 'CI';
                break;
            case 'moov_tg':
            case 'togocel':
                phone.country = 'TG';
                break;
            case 'free_sn':
            case 'orange_sn':
                phone.country = 'SN';
                break;
            case 'airtel_ne':
                phone.country = 'NE';
                break;
            case 'orange_ml':
                phone.country = 'ML';
                break;
            case 'mtn_open_gn':
                phone.country = 'GN';
                break;
        }
        this.setState({ phone });
    }

    hidePhone(mode: string): boolean {
        return MOBILE_MONEY_MODES_WITHOUT_PHONE.includes(mode);
    }

    handleModeChange = (e: any) => {
        const mode = e.target.value
        this.modeChange(mode);

        const phone = this.state.phone;

        this.setState({
            mode, phone, formValid: this.formValid(mode, phone)
        });
    }

    formValid(mode, phone) {
        if (this.hidePhone(mode)) {
            return true;
        }

        return phone.isValid();
    }

    handlePhoneChange = (e: any) => {
        this.state.phone.number = e.target.value;
        this.setState({
            phone: this.state.phone, formValid: this.state.phone.isValid()
        });
    }

    openDialog(id: string, url: string) {
        RedirectModal.create(`fexm-${id}`, `fexf-${id}`, url);
        RedirectModal.open(`fexm-${id}`, () => {
            this.checkStatus(this.token, this.intent.reference);
        });
    }

    handleSubmit = (e: any) => {
        e.preventDefault();

        if (!this.state.formValid) {
            return;
        }

        this.setState({ loading: true });
        this.service.createPayment(
            this.state.mode, this.token,
            { phone_number: this.state.phone.toJson() }
        )
            .then((intent: PaymentIntent) => {
                Shared.intent = intent;
                this.intent = intent;

                if (['orange_sn', 'orange_ci', 'orange_ml'].includes(this.state.mode)) {
                    this.openDialog(intent.reference, intent.metadata.payment_url);
                } else {
                    this.checkStatus(this.token, intent.reference);
                }
            })
            .catch(({ message, errors }) => {
                this.setState({ error: message, loading: false });
            });
    }

    public render() {
        return (
            <form id="mobile-money" class="payment-container d-flex flex-column h-100" onSubmit={this.handleSubmit}>
                <fieldset class="modal-body mb-auto" disabled={ this.state.loading }>
                    {
                        this.state.transaction?.customer.account_id == Config.guffeAccountId && this.state.transaction?.custom_metadata.company_name &&
                        <div class="form-group">
                            <label for="company_name"><Text id="mobile_money.company_name" /></label>
                            <div class="font-weight-bold">
                                {this.state.transaction?.custom_metadata.company_name}
                            </div>
                        </div>
                    }

                    <TranasctionErrorComponent error={this.state.error} error_code={this.state.error_code} />

                    <div class="form-group">
                        <label for="mode"><Text id="mobile_money.choose_operator" /></label>
                        <select class="custom-select"
                            value={this.state.mode}
                            onChange={this.handleModeChange}>
                            {
                                this.state.availableModes.map((m, k) => (
                                    <option value={m} key={k}>
                                        <Text id={`mobile_money.${m}`} />
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div class={classnames('form-group', { 'd-none': this.hidePhone(this.state.mode) })}>
                        <label for="phone_number"><Text id="mobile_money.phone_number" /></label>
                        <div class="input-group phone-number-container">
                            <div class="input-group-prepend">
                                <span class={`input-group-text ${this.state.formValid ? '' : 'is-invalid'}`}>
                                    <i class={`flag-icon flag-icon-${this.state.phone.country.toLowerCase()}`} />
                                </span>
                            </div>
                            <input id="phone_number" required={!this.hidePhone(this.state.mode)}
                                value={this.state.phone.number}
                                onInput={this.handlePhoneChange}
                                type="text"
                                placeholder="Votre numéro de téléphone"
                                class={`form-control ${this.state.formValid ? '' : 'is-invalid'}`} />
                        </div>
                    </div>
                    {this.state.loading &&
                        <p class="small"><Text id={`waiting_validation.${this.state.mode}`} /></p>
                    }
                </fieldset>
                <div class="modal-footer">
                    { this.state.mode &&
                        <PayButtonComponent loading={ this.state.loading }
                            disabled={ !this.state.formValid }/>
                    }
                </div>
            </form>
        )
    }

    private checkStatus(token: string, payment_intent_reference: string, startTime: Date = new Date()) {
        this.service.paymentStatus(this.state.mode, token, payment_intent_reference)
            .then((intent: PaymentIntent) => {
                const now = (new Date()).getTime();
                const diff = (now - startTime.getTime()) / 60000;

                if (diff < 10) {
                    if (intent.status === 'approved') {
                        Shared.intent = intent;
                        route('/status/success', true);
                    } else if (intent.status === 'pending') {
                        setTimeout(() => {
                            this.checkStatus(token, payment_intent_reference, startTime);
                        }, 5000);
                    } else { // Show
                        this.setState({
                            error: 'Transaction échouée. Veuillez reessayer',
                            error_code: intent.last_error_code,
                            loading: false
                        });
                    }
                } else {
                    this.setState({
                        error: 'Transaction échouée. Veuillez reessayer',
                        error_code: intent.last_error_code,
                        loading: false
                    });
                }
            })
            .catch(({ message }) => {
                this.setState({ error: message, loading: false });
            });
    }
}
