import { Component, h } from 'preact';
import { Text } from 'preact-i18n';
import classnames from 'classnames';
import {
    isMobilePaymentModeAllowed,
    makeSureTransactionWasVerified, processTransactionCancelation
} from '../utils';
import {ActiveLink} from './active-link';
import { Config } from '../app.configs';
import { Transaction } from '../models';

interface Props{
    onLanguageChanged: Function;
    activeLang: string;
    position?: 'left' | 'right';
}

interface State{
    transaction?: Transaction
    showMobile: boolean;
    cardMode: string|boolean;
}

export default class NavComponent extends Component<Props, State> {
    state: State = {
        showMobile: false,
        cardMode: null,
    };

    constructor() {
        super();
        makeSureTransactionWasVerified(verifyTrans => {
            const transaction = verifyTrans.transaction;
            this.setState({
                showMobile : isMobilePaymentModeAllowed(verifyTrans),
                cardMode : verifyTrans.getCardMode(),
                transaction
            });
        });
    }

    changeLanguage(lang: string) {
        this.props.onLanguageChanged(lang);
    }

    onClose = () => {
        processTransactionCancelation();
    }

    cardMenuItem(position: 'left' | 'right') {
        return <ActiveLink href="/card" class="list-group-item list-group-item-action" activeClassName='active'>
            <div class="d-flex w-100 justify-content-between align-items-center">
                <span class="nav-icon align-self-start mr-2 mt-1">
                    <i class="fad fa-credit-card fa-lg"></i>
                </span>

                <div>
                    <h6 class="mb-sm-1 mb-0"><Text id="mobile_menu.card" /></h6>
                    <p class={classnames('mb-0 sub-title', {'d-sm-block d-none': position === 'left' })}>
                        <Text id="mobile_menu.card_alt" />
                    </p>
                </div>

                <span class="ml-auto next">
                    <i className="far fa-chevron-right fa-lg"></i>
                </span>
            </div>
        </ActiveLink>
    }

    mobileMenuItem(position: 'left' | 'right') {
        return <ActiveLink href="/mobile-money" class="list-group-item list-group-item-action" activeClassName='active'>
            <div class="d-flex w-100 align-items-center">
                <span class="nav-icon align-self-start mr-2 mt-1">
                    <i class="fad fa-mobile fa-lg"></i>
                </span>
                <div>
                    <h6 class="mb-sm-1 mb-0"><Text id="mobile_menu.mobile_money" /></h6>
                    <p class={classnames('mb-0 sub-title', {'d-sm-block d-none': position === 'left' })}>
                        <Text id="mobile_menu.mobile_money_alt" />
                    </p>
                </div>

                <span class="ml-auto next">
                    <i className="far fa-chevron-right fa-lg"></i>
                </span>
            </div>
        </ActiveLink>
    }

    public render() {
        const { position = 'left' } = this.props;

        return (
            <div id="menu">
                <div class="header">
                    <Text id="mobile_menu.title" />
                </div>

                <div class="list-group">
                    {
                        this.state.transaction?.customer.account_id == Config.evisaAccountId &&
                        this.cardMenuItem(position)
                    }

                    {
                        this.state.showMobile && this.mobileMenuItem(position)
                    }

                    {
                        this.state.transaction?.customer.account_id != Config.evisaAccountId &&
                        this.cardMenuItem(position)
                    }

                    <div class="list-group-item list-group-item-action">
                        <div class="d-flex w-100 align-items-center">
                            <a class="d-flex align-items-center" onClick={ this.onClose } role="button">
                                <span class="nav-icon mr-2">
                                    <i class="fad fa-times fa-lg"></i>
                                </span>

                                <div>
                                    <h6 class="my-1"><Text id="mobile_menu.cancel" /></h6>
                                </div>
                            </a>
                            <div class="btn-group btn-group-sm ml-auto shadow-none lang-group lang-switch" role="group" aria-label="lang">
                                {['en', 'fr'].map(lang => {
                                    return <button type="button"
                                        class={ classnames("btn btn-link", {'active': this.props.activeLang == lang }) }
                                        onClick={() => this.props.onLanguageChanged(lang) }>{lang}</button>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
